import './bootstrap';
import Alpine from 'alpinejs';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { addToCart, disableSubmitOnClick } from './cart'; // Import du fichier cart.js
import { initCountrySelect } from './country'; // Import direct de la fonction

// Assigner la fonction initCountrySelect au scope global
window.initCountrySelect = initCountrySelect;

window.Alpine = Alpine;
Alpine.start();
AOS.init();
// Charger des modules uniquement lorsque nécessaire
// Charger des modules uniquement lorsque nécessaire
document.addEventListener('DOMContentLoaded', function () {
    setTimeout(function () {
        import('./swiper').then(({ initSwiper }) => {
            initSwiper();
        });
        import('./scrolltotop').then(({ initScrollToTop }) => {
            initScrollToTop();
        });

        import('./cart.js').then(({ addToCart, disableSubmitOnClick }) => {
            window.addToCart = addToCart;
            window.disableSubmitOnClick = disableSubmitOnClick;
        });

    });
});